@media print {

  @page {
    //size: 58mm 297mm !important;
    //margin: 10px auto !important;
  }
  html, body {
    width: 100% !important;
    height: initial !important;
    margin: 0 auto;
    overflow: initial !important;
    -webkit-print-color-adjust: exact !important;

  }

  .ticket-print {
    width: 100%;
    margin-left: 20px !important;
    //margin-right: 57mm !important;
    //padding-right: 57mm !important;
    padding-bottom: 40px !important;
    //margin-top: 1rem;
    display: block !important;
    page-break-before: auto;
    color: black !important;
    //margin: 0 10px;
    //width: calc(2/12 * 100%);

    /*    img {
          display: block;
          margin: 0 auto !important;
        }*/

    h1, h2, h3, h4, h5, p, div, span, b {
      font-family: "Arial Black", serif !important;
      color: black !important;
    }

    hr {
      border-top: 1px solid rgb(0, 0, 0) !important;
    }

    table {
      width: 100%;
      border-collapse: collapse;
      color: black !important;

    }
  }
}

@media print {

  html, body {
    width: 100% !important;
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
    background-color: white !important;
  }

  table b.val, p b.val {
    color: #000000;
  }


  .text-dark {
    color: black !important;

  }

  .card {
    box-shadow: unset !important;
  }


  .pv-print {
    display: block;
    page-break-before: auto;
    //padding: 3mm;
    margin-top: 250px;
    margin-left: 70px;
    margin-right: 70px;
    font-size: 19px;
  }

  .aut-print {
    display: block;
    page-break-before: auto;
    //padding: 3mm;
    margin-top: 270px;
    margin-left: 70px;
    margin-right: 70px;
    font-size: 14px;
  }

  .cert-print {
    display: block;
    page-break-before: auto;
    padding: 3mm;
    width: 700px;
    padding-top: 80px !important;
    margin-top: 520px;
    margin-bottom: 0px;
    margin-left: 100px;
    margin-right: 0px;
    //margin: 490px 50px 0 20px;
    font-size: 14px;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);


  }


  /*@page {

    size: A4 portrait;
    margin: 0;

  }*/
}
