//Import Icon scss
@import "./assets/scss/icons.scss";

//import Light Theme
@import "./assets/scss/bootstrap.scss";
@import "./assets/scss/app.scss";

//Import Dark theme
// @import "./assets/scss/bootstrap-dark.scss";
// @import "./assets/scss/app-dark.scss";

// RTL mode
// @import "./assets/scss/bootstrap.scss";
// @import "./assets/scss/app-rtl.scss";


.accordian-bg-products {
  background-color: $gray-300;
}

.nav-link {
  cursor: pointer;
}

.m-pointer {
  cursor: pointer;

}

.dataTables_filter, .dataTables_paginate {
  float: right;

  .pagination {
    .page-link {
      border-radius: 30px !important;
      margin: 0 3px;
      border: none;
      text-align: center;
      cursor: pointer;
    }
  }
}

.rdw-editor-main {
  border: 1px solid $gray-300;
  height: 239px;
}

.dz-message {
  text-align: center;
  padding: 100px;
}

.react-datepicker-wrapper {
  width: 100% !important;
}

.ReactModal__Overlay {
  z-index: 1001 !important;
}

.rangeslider__fill {
  background-color: $primary !important;
}

.react-bootstrap-table {
  padding-top: 0px !important;
}

.square-switch {
  .react-switch-bg {
    border-radius: 5px !important;
  }
}

input[type="range"] {
  -webkit-appearance: auto !important;
}

.chat-conversation-height {
  min-height: 500px;
}

.expand-cell {
  cursor: pointer !important;
}

/*
LOADING SPINNER
*/

.block-loader.brelative {
  position: relative;
  top: 155px;
}

.block-loader {


  .lds-roller {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }

  .lds-roller div {
    animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    transform-origin: 40px 40px;

  }

  .lds-roller div:after {
    content: " ";
    display: block;
    position: absolute;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    //background: #fff;
    background: red;
    margin: -4px 0 0 -4px;
  }

  .lds-roller div:nth-child(1) {
    animation-delay: -0.036s;
  }

  .lds-roller div:nth-child(1):after {
    top: 63px;
    left: 63px;
  }

  .lds-roller div:nth-child(2) {
    animation-delay: -0.072s;
  }

  .lds-roller div:nth-child(2):after {
    top: 68px;
    left: 56px;
  }

  .lds-roller div:nth-child(3) {
    animation-delay: -0.108s;
  }

  .lds-roller div:nth-child(3):after {
    top: 71px;
    left: 48px;
  }

  .lds-roller div:nth-child(4) {
    animation-delay: -0.144s;
  }

  .lds-roller div:nth-child(4):after {
    top: 72px;
    left: 40px;
  }

  .lds-roller div:nth-child(5) {
    animation-delay: -0.18s;
  }

  .lds-roller div:nth-child(5):after {
    top: 71px;
    left: 32px;
  }

  .lds-roller div:nth-child(6) {
    animation-delay: -0.216s;
  }

  .lds-roller div:nth-child(6):after {
    top: 68px;
    left: 24px;
  }

  .lds-roller div:nth-child(7) {
    animation-delay: -0.252s;
  }

  .lds-roller div:nth-child(7):after {
    top: 63px;
    left: 17px;
  }

  .lds-roller div:nth-child(8) {
    animation-delay: -0.288s;
  }

  .lds-roller div:nth-child(8):after {
    top: 56px;
    left: 12px;
  }

  @keyframes lds-roller {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

}

.loader-center {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader-top {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}


.hidden {
  display: none !important;
}

.form-control {
  color: black;
  font-weight: bold;
}

.error_pwd {
  margin-top: -10px;
  color: red;
  font-size: 13px;
}

.text-red {
  color: red;
  background: red;
}

.color-red {
  color: red !important;

}

.text-white {
  color: white !important;
}


.MuiGrid-item label {
  margin-left: 9px;
}

.MuiGrid-item input {
  margin-top: -2px;
}

/*.prepend-icon{
  width: 64px;
  background: white;
  border-top: 1px solid #ced4da;
  border-bottom: 1px solid #ced4da;
  border-left: 1px solid #ced4da;
}*/

.mInputGroup {
  border: 1px #ced4da solid;
  padding: 10px;
  border-radius: 0.25rem;
}

.mInputGroup input {
  border: none;
  background-color: transparent;
}

.mInputGroup input:focus,
select:focus,
textarea:focus,
button:focus {
  //outline: none;
}

.mInputGroup i {
  color: gray;
  top: 38% !important;
}

.mform-control {
  height: 27px !important;
  padding-top: 7px !important;
  padding-left: 33px !important;
}

.select-prefix-icon {
  position: absolute;
  top: 6px;
  left: 20px;
  font-size: 24px;
  color: #d0d0d0;
}

.select-input {
  height: 48px;
  padding-left: 37px;
}

.mbtn-danger {
  color: #fff;
  background-color: #282324;
  border-color: #fafafa;
}

.mbtn-warning {
  background-color: #d3a033;
}

.MuiTableCell-root {
  //padding:6px!important;
}

.infowindow-img {
  height: 70px;
  object-fit: contain;
}

tbody tr:nth-child(odd) {
  background: #add8e617
}

.authentication-bg {
  height: 110vh;
}

.btn-white {
  background: white !important;
  color: black !important;
}

.authentication-page-content {
  min-height: 100vh;
}

.authentication-page-login {
  min-height: 75vh !important;
}

.authentication-bg-register {
  min-height: 100vh !important;
}

.auth-form-group-custom .auti-custom-input-icon {
  color: #d0d0d0;
}

.icon-align-center {
  position: relative;
  top: 2px;
}

.MuiInputBase-input.MuiInput-input {
  margin-left: 10px !important;
}

.text-blue {
  color: deepskyblue !important;
}

.redirect-link {
  color: deepskyblue !important;
  text-decoration: underline;
  cursor: pointer;

}

.pointer {
  cursor: pointer;
}

.description-readonly {
  margin-left: 1px !important;
}

.select-m-top {
  margin-top: -9px;
}

.grey-bg {
  //background-color: #fafafa !important;
}

.price-tag {
  //background: #6eb023;
  padding: 5px;
  padding-right: 1px;
  width: 100px;
  border-radius: 5px 5px;
  border: 1px solid lightgrey;
  color: black;
}

.border-articles {
  border: 1px solid lightgrey;
  border-radius: 5px;
}

.login-container {
  height: 600px;
  width: 400px;
  margin: 5% auto !important;
}

.ticket-print {
  td {
    padding: 0 !important;
  }
}


.min-h-25 {
  min-height: 25px;
}


.profile-choice {
  background-color: rgb(255, 255, 255);
  color: black;
}

/*/deep/ .progress-bar {
  background-color: #eabf00 !important;
}*/

.twitter-bs-wizard .twitter-bs-wizard-nav .nav-link.active .step-number {
  background-color: #1b1a20;
  border: 1px solid white;
}

.twitter-bs-wizard .twitter-bs-wizard-pager-link li a {
  background-color: #1b1a20;
}

.twitter-bs-wizard .twitter-bs-wizard-pager-link li.disabled a {
  background-color: #1b1a2070;
}

.progress-public {
  background-color: #1b1a20 !important;

}


.progress-custom {
  background-color: purple;
  color: white;
}

.mbtn-outline-light {
  color: #eff2f7;
  border-color: #c0c0c0;
}

.edit-doc h5 {
  width: 100% !important;
}

table.preview, .preview th, .preview td {
  border: 1px solid grey;
  border-collapse: collapse;
}


.text-go {
  color: darkgreen;
  font-weight: bold;
}


.ReactModal__Overlay {
  z-index: 9999 !important;
}

.bold {
  font-weight: bold;
}

.text-black {
  color: black !important;
}

