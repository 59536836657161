.pv {
  font-size: 12px;

  .text-uppercase {
    text-transform: uppercase !important;
  }


  td {
    padding: 4px;
  }

  .empty {
    width: 20px;
  }

  .full {

  }

  .periode, tr {
    background-color: white !important;
  }

  .header {
    background-color: #eff7ff !important;
    text-align: center;
    font-weight: bolder;
  }
}

.cert-sub-text {
  margin-top: -19px;
  margin-left: 140px;
}

.cert-sub-text2 {
  margin-top: -19px;
  margin-left: 10px;
}

.certificat-header {
  table {
    margin-bottom: -15px;
  }

  p {
    font-size: 13px;

    small {
      font-size: 11px;
    }
  }

}

table b.val, p b.val {
  font-size: 10px;
  font-weight: bolder;
  text-transform: uppercase;
  color: #830000;
  //color: #000000;
}

#watermark {
  position: absolute;
  top: 30%;
  left: 10%;
  opacity: 0.1;
  font-size: 60px;
  transform: rotate(-60deg);
  letter-spacing: 50px;
}

.blurred-qr {
  -webkit-filter: blur(2px);
}

.react-confirm-alert-overlay {
  z-index: 1100;
}

.react-confirm-alert-overlay {
  background: #838383e6;
}

.product-border-bottom {
  border-bottom: 2px solid black;
}


.add-btn{
  display: inline-block;
  width: 14%;
  border: 1px solid grey;
  text-align: center;
  border-radius: 3px 3px;
}