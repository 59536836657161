html, body {
  width: unset !important;
  height: unset !important;
  background-color: white;
}

.card {
  box-shadow: 0 0 2px grey !important;
}

.page-content {
  background-color: white !important;
}

.header-right {
  //float: right;

  span, div {
    font-weight: bold !important;
  }

  button {
    margin-right: 0;
  }
}